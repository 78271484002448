let init = {
  botList: {},
  botReport: {},
  botDailyReport: {},
};

const botReducer = (state = init, { type, payload }) => {
  switch (type) {
    case "BOT_CONFIG_LIST": {
      return {
        ...state,
        botList: payload,
      };
    }
    case "GET_BOT_REPORT": {
      return {
        ...state,
        botReport: payload,
      };
    }
    case "GET_BOT_DAILY_REPORT": {
      return {
        ...state,
        botDailyReport: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default botReducer;
