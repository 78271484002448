import {
  ADD_BOT_CONFIG,
  ADD_BOT_LIST,
  DELETE_BOT_LIST,
  GET_BOT_CONFIG,
  GET_BOT_DAILY_REPORT,
  GET_BOT_LIST,
  GET_BOT_REPORT,
  UPDATE_BOT_CONFIG,
  UPDATE_BOT_LIST,
} from "Redux/route";

//----------------------------------------- Bot --------------------------------------------------------
export const addBotList = (payload) => async (dispatch, getState, api) => {
  return await api
    .post(ADD_BOT_LIST, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getBotList = (payload) => async (dispatch, getState, api) => {
  return await api
    .post(GET_BOT_LIST, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateBotList = (payload) => async (dispatch, getState, api) => {
  return await api
    .put(UPDATE_BOT_LIST, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteBotList = (payload) => async (dispatch, getState, api) => {
  return await api
    .delete(DELETE_BOT_LIST, { data: payload })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getBotConfig = (payload) => async (dispatch, getState, api) => {
  return await api
    .get(GET_BOT_CONFIG, {})
    .then((res) => {
      console.log(res?.data?.data);
      if (res?.data?.success) {
        dispatch({
          type: "BOT_CONFIG_LIST",
          payload: res?.data?.data,
        });
      }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const addBotConfig = (payload) => async (dispatch, getState, api) => {
  return await api
    .post(ADD_BOT_CONFIG, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateBotConfig = (payload) => async (dispatch, getState, api) => {
  return await api
    .put(UPDATE_BOT_CONFIG, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getBotReport = (payload) => async (dispatch, getState, api) => {
  return await api
    .post(GET_BOT_REPORT, {})
    .then((res) => {
      if (res?.data?.success) {
        dispatch({
          type: "GET_BOT_REPORT",
          payload: res?.data?.data,
        });
      }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getBotDailyReport = (payload) => async (dispatch, getState, api) => {
  return await api
    .post(GET_BOT_DAILY_REPORT, {})
    .then((res) => {
      if (res?.data?.success) {
        dispatch({
          type: "GET_BOT_DAILY_REPORT",
          payload: res?.data?.data,
        });
      }
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
