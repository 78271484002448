const BotIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="512.000000pt"
      height="440.000000pt"
      viewBox="0 0 512.000000 440.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,440.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path d="M2423 4375 c-157 -48 -272 -194 -281 -356 -10 -166 72 -310 218 -384 l50 -25 0 -255 0 -255 -752 -2 -753 -3 -60 -29 c-77 -38 -163 -124 -201 -201 l-29 -60 -5 -315 -5 -315 -277 -3 -276 -2 -26 -31 -26 -31 0 -555 0 -555 29 -29 29 -29 276 0 275 0 3 -313 3 -313 38 -76 c44 -91 102 -146 197 -192 l65 -31 1625 0 1625 0 55 26 c116 56 195 144 230 256 18 59 20 93 20 354 l0 289 296 0 296 0 29 29 29 29 0 555 0 555 -26 31 -26 31 -299 0 -299 0 0 289 c0 256 -2 296 -19 350 -37 121 -110 204 -227 258 l-59 28 -750 5 -750 5 -3 252 -2 251 55 26 c63 29 144 105 174 165 142 277 -50 593 -358 590 -36 0 -85 -6 -108 -14z m-418 -2003 c268 -126 322 -464 106 -664 -130 -121 -324 -142 -477 -52 -59 35 -131 115 -162 182 -24 51 -27 69 -27 167 0 100 2 115 28 167 54 110 162 200 273 228 72 18 187 5 259 -28z m1451 -6 c84 -42 148 -107 191 -194 26 -52 28 -67 28 -167 0 -98 -3 -116 -27 -167 -117 -251 -439 -316 -639 -130 -207 191 -167 519 80 651 79 42 122 51 215 46 66 -3 91 -9 152 -39z m28 -1319 c55 -31 76 -76 76 -162 0 -83 -20 -129 -69 -162 l-34 -23 -897 0 -897 0 -34 23 c-49 33 -69 79 -69 162 0 86 21 131 76 162 l39 23 885 0 885 0 39 -23z" />
      </g>
    </svg>
  );
};
export default BotIcon;
