let init = {
  dashboardDetails: {},
};

const dashboardReducer = (state = init, { type, payload }) => {
  switch (type) {
    case "GET_DASHBOARD_DATA": {
      return {
        ...state,
        dashboardDetails: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default dashboardReducer;
