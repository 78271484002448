import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps } from "../../../../utils";
import TabPanel from "../../../../Components/TabPanel";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSingleGameDetails } from "../../../../Redux/games/action";
import PhonePe from "./PhonePe";
import Razorpay from "./Razorpay";
import Aws from "./Aws";
import Sandbox from "./Sandbox";
import Zoop from "./Zoop";
import Firebase from "./Firebase";
import SesAws from "./SesAws";

const tabArray = [
  { id: 0, active: true, label: "Phone Pe", component: PhonePe },
  {
    id: 1,
    active: true,
    label: "Razorpay",
    component: Razorpay,
  },
  { id: 2, active: true, label: "Amazon Web Services", component: Aws },
  { id: 3, active: true, label: "SES AWS", component: SesAws },
  { id: 4, active: true, label: "Sandbox", component: Sandbox },
  { id: 5, active: true, label: "Zoop", component: Zoop },
  { id: 6, active: true, label: "Firebase", component: Firebase },
];

const EnvTabDetails = ({ handleOpenModal, redirectApiProps }) => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [tab, setTab] = useState(tabArray);

  useEffect(() => {
    if (state?.isRedirectTab) {
      setValue(1);
    }
  }, [state]);

  useEffect(() => {
    dispatch(getSingleGameDetails({ gameId: id }));
  }, [id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }} className={"tab"}>
        <Box
          className="bg_white tab_inner_section"
          sx={{ borderBottom: 1, borderColor: "divider" }}
          pl={3}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            className={"tab_bg_white"}
            scrollButtons="auto"
            variant="scrollable"
          >
            {tab
              ?.filter((item) => item?.active !== false)
              ?.map((item, index) => {
                return (
                  <Tab
                    className={"tab_listing"}
                    key={item}
                    label={item?.label}
                    {...a11yProps(index)}
                  />
                );
              })}
          </Tabs>
        </Box>
      </Box>
      {tab
        ?.filter((item) => item?.active !== false)
        ?.map(({ id, component: Component, active }, key) => {
          return (
            <TabPanel value={value} index={key}>
              <Component
                handleOpenModal={handleOpenModal}
                tabChangeArray={tab?.filter((item) => item?.active !== false)}
                setValue={setValue}
                value={value}
                redirectApiProps={redirectApiProps}
              />
            </TabPanel>
          );
        })}
    </>
  );
};
export default EnvTabDetails;
