import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BootstrapTooltip, currencyFormat } from "utils";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import info from "../../../assets/images/info.svg";
import { getBotDailyReport, getBotReport } from "Redux/bot/action";
import CustomTable from "hoc/CommonTable";
import { TableCell } from "@mui/material";
import moment from "moment";

const BotReport = () => {
  const dispatch = useDispatch();
  const botReportData = useSelector((state) => state?.botReducer?.botReport);
  const botDailyReportData = useSelector(
    (state) => state?.botReducer?.botDailyReport
  );
  const [botDailyReport, setBotDailyReport] = useState([]);

  useEffect(() => {
    dispatch(getBotReport());
    dispatch(getBotDailyReport());
  }, []);

  useEffect(() => {
    if (botDailyReportData.length > 0) {
      botDailyReportData?.sort(function (a, b) {
        var dateA = new Date(a.date),
          dateB = new Date(b.date);
        if (dateB < dateA) {
          return -1;
        } else {
          return 1;
        }
      });
    }
    setBotDailyReport(botDailyReportData);
  }, [botDailyReportData]);

  const columns = [
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      isDisbanding: true,
      label: "Date",
      type: "custom",
      render: (row, i) => {
        return (
          <TableCell className={"config_text"}>
            {moment(row?.date).format("DD-MM-yyyy")}
          </TableCell>
        );
      },
    },
    {
      id: "WinOrLossAmount",
      numeric: false,
      disablePadding: false,
      isDisbanding: true,
      label: "Win Or Loss Amount",
      type: "custom",
      render: (row, i) => {
        return <TableCell>{currencyFormat(row?.WinOrLossAmount)}</TableCell>;
      },
    },
  ];

  return (
    <Box>
      <Paper sx={{ mb: 2 }} className="outer-box">
        <div className={"tds_report_all_game tds_challan_user"}>
          <Paper
            sx={{ mb: 2 }}
            className="outer-box tds_challan_table left_margin_challan"
          >
            <div className={"game_tab_details_box"}>
              <h4>Total Bot Users</h4>
              <BootstrapTooltip
                title={
                  <div className={"tooltip_details_revenue"}>
                    <p>Game total bot users.</p>
                  </div>
                }
              >
                <img src={info} alt={""} />
              </BootstrapTooltip>
            </div>
            <p>{botReportData?.totalBotUsers || 0}</p>
          </Paper>
          <Paper sx={{ mb: 2 }} className="outer-box tds_challan_table">
            <div className={"game_tab_details_box"}>
              <h4>Total Initial Cash</h4>
              <BootstrapTooltip
                title={
                  <div className={"tooltip_details_revenue"}>
                    <p>Total Initial Cash Balance.</p>
                  </div>
                }
              >
                <img src={info} alt={""} />
              </BootstrapTooltip>
            </div>
            <p>{currencyFormat(botReportData?.totalInitialCash || 0)}</p>
          </Paper>
          <Paper
            sx={{ mb: 2 }}
            className="outer-box tds_challan_table right_margin_challan"
          >
            <div className={"game_tab_details_box"}>
              <h4>Total Game Win Cash</h4>
              <BootstrapTooltip
                title={
                  <div className={"tooltip_details_revenue"}>
                    <p>Total Game Win Cash Balance. </p>
                  </div>
                }
              >
                <img src={info} alt={""} />
              </BootstrapTooltip>
            </div>
            <p>{currencyFormat(botReportData?.totalGameWinCash || 0)}</p>
          </Paper>
        </div>
        <div className={"tds_report_all_game tds_challan_user"}>
          <Paper
            sx={{ mb: 2 }}
            className="outer-box tds_challan_table left_margin_challan"
          >
            <div className={"game_tab_details_box"}>
              <h4>Total Balance</h4>
              <BootstrapTooltip
                title={
                  <div className={"tooltip_details_revenue"}>
                    <p>Total Balance.</p>
                  </div>
                }
              >
                <img src={info} alt={""} />
              </BootstrapTooltip>
            </div>
            <p>{currencyFormat(botReportData?.totalBalance || 0)}</p>
          </Paper>
          <Paper
            sx={{ mb: 2 }}
            className="outer-box tds_challan_table right_margin_challan"
          >
            <div className={"game_tab_details_box"}>
              <h4>Net Win Or Loss Amount</h4>
              <BootstrapTooltip
                title={
                  <div className={"tooltip_details_revenue"}>
                    <p>Net win or loss amount.</p>
                  </div>
                }
              >
                <img src={info} alt={""} />
              </BootstrapTooltip>
            </div>
            <p>{currencyFormat(botReportData?.netWinOrLossAmount || 0)}</p>
          </Paper>
        </div>
      </Paper>

      <Paper sx={{ mb: 2 }} className="outer-box">
        <div className={"d_flex_between"}>
          <h2>Bot Daily Report</h2>
        </div>

        <CustomTable
          headCells={columns}
          rowData={botDailyReport}
          isWinnerTitle={true}
        />
      </Paper>
    </Box>
  );
};
export default BotReport;
