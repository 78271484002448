import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";

const Aws = ({ handleOpenModal }) => {
  const envConfigList = useSelector((state) => state?.settingReducer?.envList);

  return (
    <Box>
      <Paper sx={{ mb: 2 }} className="outer-box">
        <div className={"referAndEarn_title mb_1"}>
          <div className={"d_flex justify_content_between"}>
            <h2>Amazon Web Services</h2>
            <div>
              {!envConfigList ? (
                <button
                  className={"btn mr_2"}
                  onClick={(e) => handleOpenModal("AwsEnvConfig")}
                >
                  Create Aws Env Config
                </button>
              ) : (
                <button
                  className={"btn"}
                  onClick={(e) =>
                    handleOpenModal("AwsEnvConfig", {
                      isEdit: true,
                      row: envConfigList,
                    })
                  }
                >
                  Edit Aws Env Config
                </button>
              )}
            </div>
          </div>
          <div className={"env_config"}>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>AWS Bucket Name :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.awsBucketName}</p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>AWS Bucket Region :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.awsBucketRegion}</p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>AWS Access Key :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.awsAccessKey}</p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>AWS Secret Key :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.awsSecretKey}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Box>
  );
};
export default Aws;
