import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";

const SesAws = ({ handleOpenModal }) => {
  const envConfigList = useSelector((state) => state?.settingReducer?.envList);

  return (
    <Box>
      <Paper sx={{ mb: 2 }} className="outer-box">
        <div className={"referAndEarn_title mb_1"}>
          <div className={"d_flex justify_content_between"}>
            <h2>SES AWS</h2>
            <div>
              {!envConfigList ? (
                <button
                  className={"btn mr_2"}
                  onClick={(e) => handleOpenModal("SesAwsEnvConfig")}
                >
                  Create Ses Aws Env Config
                </button>
              ) : (
                <button
                  className={"btn"}
                  onClick={(e) =>
                    handleOpenModal("SesAwsEnvConfig", {
                      isEdit: true,
                      row: envConfigList,
                    })
                  }
                >
                  Edit Ses Aws Env Config
                </button>
              )}
            </div>
          </div>
          <div className={"env_config"}>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Ses From :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.sesFrom}</p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Ses Aws Region :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.sesAwsRegion}</p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Ses Aws Access Key :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.sesAwsAccessKey}</p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Ses Aws Secret Key :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.sesAwsSecretKey}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Box>
  );
};
export default SesAws;
