import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { getMetaTagList } from "Redux/MetaTag/action";

function HelmetTag() {
  const dispatch = useDispatch();
  const [metaTag, setMetaTag] = useState();

  useEffect(() => {
    dispatch(getMetaTagList({})).then((res) => {
      setMetaTag(res?.data?.data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaTag?.appTitle ?? ""}</title>
        <meta name="description" content={metaTag?.appDescription ?? ""} />
      </Helmet>
    </>
  );
}

export default HelmetTag;
