import { ADD_META_TAG, GET_META_TAG } from "../../route";

export const getMetaTagList = (payload) => async (dispatch, getState, api) => {
  return await api
    .get(GET_META_TAG, {})
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const createMetaTag = (payload) => async (dispatch, getState, api) => {
  return await api
    .post(ADD_META_TAG, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const updateMetaTag = (payload) => async (dispatch, getState, api) => {
  return await api
    .put(ADD_META_TAG, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
