import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";

const Razorpay = ({ handleOpenModal }) => {
  const envConfigList = useSelector((state) => state?.settingReducer?.envList);

  return (
    <Box>
      <Paper sx={{ mb: 2 }} className="outer-box">
        <div className={"referAndEarn_title mb_1"}>
          <div className={"d_flex justify_content_between"}>
            <h2>Razorpay</h2>
            <div>
              {!envConfigList ? (
                <button
                  className={"btn mr_2"}
                  onClick={(e) => handleOpenModal("RazorpayEnvConfig")}
                >
                  Create Razorpay Env Config
                </button>
              ) : (
                <button
                  className={"btn"}
                  onClick={(e) =>
                    handleOpenModal("RazorpayEnvConfig", {
                      isEdit: true,
                      row: envConfigList,
                    })
                  }
                >
                  Edit Razorpay Env Config
                </button>
              )}
            </div>
          </div>
          <div className={"env_config"}>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Razorpay Webhook Secret :</h5>
                <div className="switch_box">
                  <p className={"off"}>
                    {envConfigList?.razorpayWebhookSecret}
                  </p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Razorpay Key :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.razorpayKey}</p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Razorpay Secret :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.razorpaySecret}</p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Razorpay Account Number :</h5>
                <div className="switch_box">
                  <p className={"off"}>
                    {envConfigList?.razorpayAccountNumber}
                  </p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Razorpay Purpose :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.razorpayPurpose}</p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Razorpay Payout Mode :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.razorpayPayoutMode}</p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Razorpay Payout Narration :</h5>
                <div className="switch_box">
                  <p className={"off"}>
                    {envConfigList?.razorpayPayoutNarration}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Box>
  );
};
export default Razorpay;
