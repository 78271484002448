import {
  GET_MGP_RELEASE,
  GET_PRIVACY_POLICY,
  GET_TERMS_AND_CONDITIONS_POLICY,
  SEND_OTP,
  VERIFY_OTP,
} from "Redux/route";

export const getPrivacyPolicy =
  (payload) => async (dispatch, getState, api) => {
    return await api
      .get(GET_PRIVACY_POLICY, payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  };

export const getTermsAndCondition =
  (payload) => async (dispatch, getState, api) => {
    return await api
      .get(GET_TERMS_AND_CONDITIONS_POLICY, payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  };

export const getMgpReleases = (payload) => async (dispatch, getState, api) => {
  return await api
    .get(GET_MGP_RELEASE, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getSendOtp = (payload) => async (dispatch, getState, api) => {
  return await api
    .post(SEND_OTP, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const verifyOtpDetail = (payload) => async (dispatch, getState, api) => {
  return await api
    .post(VERIFY_OTP, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};
