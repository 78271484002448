import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/MGP3.png";
import OTPInput from "react-otp-input";
import SimpleReactValidator from "simple-react-validator";
import { getSendOtp, verifyOtpDetail } from "Redux/LandingPage/action";
import Swal from "sweetalert2";

function Refer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        phone: {
          message: "Please Enter Valid Phone Number.",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/
            );
          },
          required: true,
        },
      },
    })
  );
  const [showSendOtp, setShowSendOtp] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [formData, setFormData] = useState({
    phoneNumber: "",
    referralCode: searchParams.get("ref"),
    otp: "",
    orderId: "",
  });
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const sendOTP = (e) => {
    e.preventDefault();
    getOtp();
  };

  const resendOTP = () => {
    getOtp();
  };

  const getOtp = () => {
    if (formData?.otp === "") {
      simpleValidator.current.fields.otp = true;
    }
    if (simpleValidator.current.allValid()) {
      setMinutes(1);
      setSeconds(0);
      let payload = {
        phoneNumber: formData?.phoneNumber,
        isoCode: "IN",
        referralCode: formData.referralCode,
      };
      dispatch(getSendOtp(payload)).then((res) => {
        if (res.data.success) {
          console.log(res.data.data.isAlreadyRegistered);
          setFormData({
            ...formData,
            orderId: res.data.data.payload.orderId,
          });
          if (!res.data.data.isAlreadyRegistered) {
            Swal.fire({
              title: "Success",
              text: res?.data?.message,
              allowOutsideClick: false,
              customClass: {
                container: "refer-sweetalert",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                setShowSendOtp(true);
              }
            });
          } else {
            Swal.fire({
              html: "You are already registered",
              allowOutsideClick: false,
              customClass: {
                container: "refer-sweetalert",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/");
              }
            });
          }
        } else {
          Swal.fire({
            title: "Error",
            allowOutsideClick: false,
            text: res?.data?.message || res?.data?.msg,
            customClass: {
              container: "refer-sweetalert",
            },
          });
        }
      });
      simpleValidator.current.hideMessages();
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      setMinutes(0);
      setSeconds(0);
      let payload = {
        phoneNumber: formData?.phoneNumber,
        isoCode: "IN",
        otp: formData.otp,
        referralCode: formData.referralCode,
        orderId: formData.orderId,
      };
      dispatch(verifyOtpDetail(payload)).then((res) => {
        if (res.data.success) {
          Swal.fire({
            text: "You have registered successful and now you can ready to download app",
            allowOutsideClick: false,
            customClass: {
              container: "refer-sweetalert",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
        } else {
          Swal.fire({
            title: "Error",
            allowOutsideClick: false,
            text: res?.data?.message || res?.data?.msg,
            customClass: {
              container: "refer-sweetalert",
            },
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  return (
    <div>
      <div className="refer-main">
        <div className="bg-img">
          <div className="bg-content">
            <div className="logo">
              <img src={logo} />
            </div>
            <div className="bg-text">
              <div className="pagination">
                <p>Apply Referral Code to get extra rewards</p>
              </div>
            </div>
            <form
              className="refer-form"
              onSubmit={(e) => (showSendOtp ? verifyOtp(e) : sendOTP(e))}
            >
              <div className="mobile-number">
                <label>
                  Mobile Number <span className={"validation-star"}>*</span>
                </label>
                <input
                  type="text"
                  className="input-text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={(e) =>
                    setFormData({ ...formData, phoneNumber: e.target.value })
                  }
                />
                {simpleValidator.current.message(
                  "phoneNumber",
                  formData?.phoneNumber,
                  "required|numeric|min:10|max:10|phone"
                )}
              </div>
              <div className="referal-code">
                <label>
                  Referral Code <span className={"validation-star"}>*</span>
                </label>
                <input
                  type="text"
                  className="input-text"
                  name="referralCode"
                  value={formData.referralCode}
                  onChange={(e) =>
                    setFormData({ ...formData, referralCode: e.target.value })
                  }
                  disabled
                />
                {simpleValidator.current.message(
                  "referralCode",
                  formData?.referralCode,
                  "required"
                )}
              </div>
              {showSendOtp && (
                <div className="otp-content">
                  <label>
                    OTP <span className={"validation-star"}>*</span>
                  </label>
                  <OTPInput
                    value={formData.otp}
                    onChange={(e) => setFormData({ ...formData, otp: e })}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  {simpleValidator.current.message(
                    "otp",
                    formData?.otp,
                    "required"
                  )}
                  <div className="countdown-text">
                    {seconds > 0 || minutes > 0 ? (
                      <p>
                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                        :{seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    ) : (
                      <div className="resend-otp">
                        <p>Didn't recieve code?</p>
                        <button
                          disabled={seconds > 0 || minutes > 0}
                          onClick={resendOTP}
                        >
                          Resend OTP
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="submit-button">
                <button>{showSendOtp ? "Verify OTP" : "Send OTP"}</button>
              </div>
            </form>
          </div>
          <div className="copyright">&copy; Bigwins. All Rights Reserved</div>
        </div>
      </div>
    </div>
  );
}

export default Refer;
