import { useDispatch } from "react-redux";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import PopComponent from "../../../../hoc/PopContent";
import { jsonToFormData } from "../../../../utils";
import { Box } from "@mui/material";
import FilledButton from "../../../../Components/FileButton";
import CommonModal from "../../../../hoc/CommonModal";
import { addEnvConfig, updateEnvConfig } from "Redux/settings/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  overflow: "auto",
};
const AddEnvConfig = ({ modalValue, handleOpenModal, redirectApiHandler }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const [modalDetails, setModalDetails] = useState({
    modalValue: "",
    modalName: "",
    modalIsOpen: false,
  });
  let Modal = PopComponent[modalDetails.modalName];
  const [formData, setFormData] = useState({
    phonePeHostUrl: "",
    phonePeMerchantId: "",
    phonePeSaltKey: "",
    phonePeSaltIndex: "",
    razorpayWebhookSecret: "",
    razorpayKey: "",
    razorpaySecret: "",
    razorpayAccountNumber: "",
    razorpayPurpose: "",
    razorpayPayoutMode: "",
    razorpayPayoutNarration: "",
    awsBucketName: "",
    awsBucketRegion: "",
    awsAccessKey: "",
    awsSecretKey: "",
    sandboxApiKey: "",
    sandboxSecret: "",
    sandboxVersion: "",
    zoopUrl: "",
    zoopKycAppId: "",
    zoopKycApiKey: "",
    firebaseServerKey: "",
  });

  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...formData,
        envConfigId: modalValue?.row?._id,
      };
      setLoader(true);
      dispatch(updateEnvConfig(payload)).then((res) => {
        if (res.data.success) {
          setLoader(false);
          redirectApiHandler();
          handleOpenModal("CommonPop", {
            header: "Success",
            body: res?.data?.message,
          });
        } else {
          setLoader(false);
          handleOpenModal("CommonPop", {
            header: "Error",
            body: res?.data?.message || res?.data?.msg,
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      let payload = {
        ...formData,
      };
      setLoader(true);
      dispatch(addEnvConfig(payload)).then((res) => {
        if (res.data.success) {
          setLoader(false);
          redirectApiHandler();
          handleOpenModal("CommonPop", {
            header: "Success",
            body: res?.data?.message,
          });
        } else {
          setLoader(false);
          handleOpenErrorModal("CommonPop", {
            header: "Error",
            body: res?.data?.message || res?.data?.msg,
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  const changeHandler = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleOpenErrorModal = (type, data) => {
    switch (type) {
      case "CommonPop": {
        setModalDetails({
          ...modalDetails,
          modalValue: data,
          modalName: type,
          modalIsOpen: true,
        });
        break;
      }
      default: {
        setModalDetails({ ...modalDetails, modalIsOpen: false });
      }
    }
  };

  useEffect(() => {
    if (modalValue?.isEdit) {
      console.log(modalValue);
      setFormData({
        ...formData,
        phonePeHostUrl: modalValue?.row?.phonePeHostUrl,
        phonePeMerchantId: modalValue?.row?.phonePeMerchantId,
        phonePeSaltKey: modalValue?.row?.phonePeSaltKey,
        phonePeSaltIndex: modalValue?.row?.phonePeSaltIndex,
        razorpayWebhookSecret: modalValue?.row?.razorpayWebhookSecret,
        razorpayKey: modalValue?.row?.razorpayKey,
        razorpaySecret: modalValue?.row?.razorpaySecret,
        razorpayAccountNumber: modalValue?.row?.razorpayAccountNumber,
        razorpayPurpose: modalValue?.row?.razorpayPurpose,
        razorpayPayoutMode: modalValue?.row?.razorpayPayoutMode,
        razorpayPayoutNarration: modalValue?.row?.razorpayPayoutNarration,
        awsBucketName: modalValue?.row?.awsBucketName,
        awsBucketRegion: modalValue?.row?.awsBucketRegion,
        awsAccessKey: modalValue?.row?.awsAccessKey,
        awsSecretKey: modalValue?.row?.awsSecretKey,
        sandboxApiKey: modalValue?.row?.sandboxApiKey,
        sandboxSecret: modalValue?.row?.sandboxSecret,
        sandboxVersion: modalValue?.row?.sandboxVersion,
        zoopUrl: modalValue?.row?.zoopUrl,
        zoopKycAppId: modalValue?.row?.zoopKycAppId,
        zoopKycApiKey: modalValue?.row?.zoopKycApiKey,
        firebaseServerKey: modalValue?.row?.firebaseServerKey,
      });
    }
  }, [modalValue?.isEdit]);

  return (
    <Box sx={style}>
      <div
        className={"modal_main_popup env_section_details lobby_section_details"}
      >
        <div className={"modal_popup_title pt_2 mb_2"}>
          <h2>
            {modalValue?.isEdit ? "Update Env Config" : "Add New Env Config"}
          </h2>
        </div>
        <div className={"add_admin_user_popup_content pl_2 pr_2 pb_2 mt_15"}>
          <form
            onSubmit={
              modalValue?.isEdit
                ? (e) => handleEditSubmit(e)
                : (e) => handleSubmit(e)
            }
          >
            <div className={"level_popup_form_field "}>
              <div className={"level_popup_form_field_left ml_1 row"}>
                <div className={"user_kyc_section pr_1"}>
                  <h3 className="mb_1">Razorpay :</h3>
                  <div className={"user_kyc_section_filed"}>
                    <label>Razorpay Webhook Secret </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.razorpayWebhookSecret}
                        name={"razorpayWebhookSecret"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Razorpay Key </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.razorpayKey}
                        name={"razorpayKey"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Razorpay Secret </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.razorpaySecret}
                        name={"razorpaySecret"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Razorpay Account Number </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.razorpayAccountNumber}
                        name={"razorpayAccountNumber"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Razorpay Purpose </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.razorpayPurpose}
                        name={"razorpayPurpose"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Razorpay Payout Mode </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.razorpayPayoutMode}
                        name={"razorpayPayoutMode"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Razorpay Payout Narration </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.razorpayPayoutNarration}
                        name={"razorpayPayoutNarration"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <h3 className="mt_1 mb_1">Sandbox :</h3>
                  <div className={"user_kyc_section_filed"}>
                    <label>Sandbox API Key </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.sandboxApiKey}
                        name={"sandboxApiKey"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Sandbox Secret </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.sandboxSecret}
                        name={"sandboxSecret"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Sandbox Version </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.sandboxVersion}
                        name={"sandboxVersion"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <h3 className="mt_1 mb_1">Firebase :</h3>
                  <div className={"user_kyc_section_filed"}>
                    <label>Firebase Secret Server Key </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.firebaseServerKey}
                        name={"firebaseServerKey"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className={"user_kyc_section pl_1"}>
                  <h3 className="mb_1">Phone Pe :</h3>
                  <div className={"user_kyc_section_filed"}>
                    <label>Phone Pe Host URL </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.phonePeHostUrl}
                        name={"phonePeHostUrl"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Phone Pe Merchant ID </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.phonePeMerchantId}
                        name={"phonePeMerchantId"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Phone Pe Salt Key </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.phonePeSaltKey}
                        name={"phonePeSaltKey"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Phone Pe Salt Index </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.phonePeSaltIndex}
                        name={"phonePeSaltIndex"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <h3 className="mt_1 mb_1">AWS :</h3>
                  <div className={"user_kyc_section_filed"}>
                    <label>AWS Bucket Name </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.awsBucketName}
                        name={"awsBucketName"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>AWS Bucket Region </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.awsBucketRegion}
                        name={"awsBucketRegion"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>AWS Access Key </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.awsAccessKey}
                        name={"awsAccessKey"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>AWS Secret Key </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.awsSecretKey}
                        name={"awsSecretKey"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <h3 className="mt_1 mb_1">Zoop :</h3>
                  <div className={"user_kyc_section_filed"}>
                    <label>Zoop URL </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.zoopUrl}
                        name={"zoopUrl"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Zoop KYC App ID </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.zoopKycAppId}
                        name={"zoopKycAppId"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={"user_kyc_section_filed"}>
                    <label>Zoop KYC API Key </label>
                    <div className={"user_kyc_section_input_filed"}>
                      <input
                        type={"text"}
                        onWheel={(event) => event.currentTarget.blur()}
                        value={formData?.zoopKycApiKey}
                        name={"zoopKycApiKey"}
                        onChange={(e) => changeHandler(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"formData_btn d_flex_end mt_2"}>
              <button
                className={"btn_default"}
                type={"reset"}
                onClick={() => handleOpenModal()}
              >
                Cancel
              </button>
              <FilledButton
                type={"submit"}
                value={modalValue?.isEdit ? "Update" : "Save"}
                className={"btn loader_css"}
                loading={loader}
              />
            </div>
          </form>
        </div>
      </div>
      <CommonModal
        className={"Approved-reject-section"}
        modalIsOpen={modalDetails.modalIsOpen}
        handleOpenModal={handleOpenErrorModal}
      >
        <Modal
          modalValue={modalDetails.modalValue}
          handleOpenModal={handleOpenErrorModal}
          modalIsOpen={modalDetails.modalIsOpen}
        />
      </CommonModal>
    </Box>
  );
};
export default AddEnvConfig;
