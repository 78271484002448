import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";

const Firebase = ({ handleOpenModal }) => {
  const envConfigList = useSelector((state) => state?.settingReducer?.envList);

  return (
    <Box>
      <Paper sx={{ mb: 2 }} className="outer-box">
        <div className={"referAndEarn_title mb_1"}>
          <div className={"d_flex justify_content_between"}>
            <h2>Firebase</h2>
            <div>
              {!envConfigList ? (
                <button
                  className={"btn mr_2"}
                  onClick={(e) => handleOpenModal("FirebaseEnvConfig")}
                >
                  Create Firebase Env Config
                </button>
              ) : (
                <button
                  className={"btn"}
                  onClick={(e) =>
                    handleOpenModal("FirebaseEnvConfig", {
                      isEdit: true,
                      row: envConfigList,
                    })
                  }
                >
                  Edit Firebase Env Config
                </button>
              )}
            </div>
          </div>
          <div className={"env_config"}>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Firebase Secret Server Key :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.firebaseServerKey}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Box>
  );
};
export default Firebase;
