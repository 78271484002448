import { useDispatch } from "react-redux";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { addWhatsAppSupportDetails } from "../../../../Redux/HelpAndSupport/action";
import { Box } from "@mui/material";
import FilledButton from "../../../../Components/FileButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 486,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const AddWhatsAppMobileNumberPopup = ({
  modalValue,
  handleOpenModal,
  redirectApiHandler,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        phoneNumber: {
          message: "Phone number is not valid",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/
            );
          },
          required: true,
        },
      },
    })
  );
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  const [formData, setFormData] = useState({ phoneNumber: "" });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      setLoader(true);
      dispatch(addWhatsAppSupportDetails(formData)).then((res) => {
        if (res.data.success) {
          setLoader(false);
          redirectApiHandler();
          handleOpenModal("CommonPop", {
            header: "Success",
            body: res?.data?.message,
          });
        } else {
          setLoader(false);
          handleOpenModal("CommonPop", {
            header: "Error",
            body: res?.data?.message || res?.data?.msg,
          });
        }
      });
    } else {
      simpleValidator.current.showMessages();
      forceUpdate();
    }
  };

  useEffect(() => {
    if (modalValue?.isEdit) {
      setFormData({ ...formData, phoneNumber: modalValue?.row?.phoneNumber });
    }
  }, [modalValue]);

  return (
    <Box sx={style}>
      <div className={"add_admin_user_popup modal_main_popup"}>
        <div className={"modal_popup_title"}>
          <h2>
            {modalValue?.isEdit
              ? "Update WhatsApp Number"
              : " Add WhatsApp Number"}
          </h2>
        </div>
        <div className={"add_admin_user_popup_content"}>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className={"user_kyc_section"}>
              <div className={"user_kyc_section_filed"}>
                <label>
                  WhatsApp Number <span className={"validation-star"}>*</span>
                </label>
                <div className={"user_kyc_section_input_filed"}>
                  <input
                    type={"text"}
                    maxLength={10}
                    name={"phoneNumber"}
                    onWheel={(e) => e.currentTarget.blur()}
                    placeholder={"Enter WhatsApp Number"}
                    value={formData?.phoneNumber}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      // Check if the value is numeric
                      if (!isNaN(value)) {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          phoneNumber: value
                        }));
                      }
                    }}
                  />
                </div>
                {simpleValidator.current.message(
                  " WhatsappNumber",
                  formData?.phoneNumber?.toString(),
                  "required|phoneNumber"
                )}
              </div>
            </div>
            <div className={"formData_btn"}>
              <button
                className={"btn_default"}
                type={"reset"}
                onClick={() => handleOpenModal()}
              >
                Cancel
              </button>
              <FilledButton
                type={"submit"}
                value={modalValue?.isEdit ? "Update" : "Save"}
                className={"btn loader_css"}
                loading={loader}
              />
            </div>
          </form>
        </div>
      </div>
    </Box>
  );
};
export default AddWhatsAppMobileNumberPopup;
