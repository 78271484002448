import React from "react"
import site_logo from "../../../src/images/site-img/sitelogo.png"
import { Link } from "react-router-dom";

function Footer() {
    return (
        <>
            <div className="footer-main">
                <div className="f-part-main container">
                    <div className="footer-part-one">
                        <div className="logo">
                            <img src={site_logo} width="80px" alt="" />
                        </div>
                    </div>
                    <div className="footer-part-two">
                        <div className="footer-list">
                            <ul>
                                <Link to="/privacypolicy">
                                    <li>Privacy Policy</li>
                                </Link>
                                <Link to="/term-condition">
                                    <li>Term and Condition</li>
                                </Link>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className=" f-end-txt">
                    <p>Copyrights © 2021 All Rights Reserved by </p>
                </div>
            </div>
        </>
    )
}



export default Footer