import React, { useEffect, useState } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Header from "Components/Header/Header";
import Footer from "Components/Footer/Footer";
import { useDispatch } from "react-redux";
import { getPrivacyPolicy } from "Redux/LandingPage/action";
import HTMLReactParser from "html-react-parser";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  const dispatch = useDispatch();
  const [privacyPolicy, setPrivacyPolicy] = useState("<h2>Data Not Found</h2>");

  useEffect(() => {
    dispatch(getPrivacyPolicy()).then((res) => {
      if (res?.data?.status === "success") {
        setPrivacyPolicy(res?.data?.data?.description);
      }
    });
  }, []);

  return (
    <div style={{ fontFamily: "Josefin Sans" }}>
      <Header />
      <div className="privacypolicys-main">
        <div className="bg-img">
          <div className="bg-text">
            <h2>Privacy Policy</h2>
            <div className="pagination">
              <Link to="/">
                <p>Home</p>
              </Link>
              <p>
                <ArrowRightAltIcon />
              </p>
              <p>Privacy Policy</p>
            </div>
          </div>
        </div>
        <div className="policy-txt container">
          {HTMLReactParser(privacyPolicy)}
        </div>
      </div>
      <div className="downlode-btns">
        <div className="">
          <button className="btn-theme">
            <AppleIcon />
            Download iOS App
          </button>
          <button className="btn-theme">
            <AndroidIcon />
            Download Android App
          </button>
        </div>
      </div>
      <Footer style={{ Padding: "0px" }} />
    </div>
  );
}

export default PrivacyPolicy;
