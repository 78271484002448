import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";

const Sandbox = ({ handleOpenModal }) => {
  const envConfigList = useSelector((state) => state?.settingReducer?.envList);

  return (
    <Box>
      <Paper sx={{ mb: 2 }} className="outer-box">
        <div className={"referAndEarn_title mb_1"}>
          <div className={"d_flex justify_content_between"}>
            <h2>Sandbox</h2>
            <div>
              {!envConfigList ? (
                <button
                  className={"btn mr_2"}
                  onClick={(e) => handleOpenModal("SandboxEnvConfig")}
                >
                  Create Sandbox Env Config
                </button>
              ) : (
                <button
                  className={"btn"}
                  onClick={(e) =>
                    handleOpenModal("SandboxEnvConfig", {
                      isEdit: true,
                      row: envConfigList,
                    })
                  }
                >
                  Edit Sandbox Env Config
                </button>
              )}
            </div>
          </div>
          <div className={"env_config"}>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Sandbox API Key :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.sandboxApiKey}</p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Sandbox Secret :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.sandboxSecret}</p>
                </div>
              </div>
            </div>
            <div className={"main_setting_block"}>
              <div className="main_switch_block">
                <h5>Sandbox Version :</h5>
                <div className="switch_box">
                  <p className={"off"}>{envConfigList?.sandboxVersion}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Box>
  );
};
export default Sandbox;
